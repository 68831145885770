import { defaultLastUpdate, getFetchFilters } from '../utils';
import { LAST_UPDATE_DATE_FORMAT } from '@/constants';
import { switchDateFormat, formatRelativeTime } from '@/helpers/format-date-service';
import apiFactory from '@/api';
import i18n from '@/i18n';
import router from '@/routes';
import toastr from '@/helpers/init-toastr';
import { Events, EventBus } from '@/events';
const api = apiFactory.get('projects');
const keywordsApi = apiFactory.get('keywords');

import {
    CLEAR_REFRESH_PROGRESS,
    SET_IS_REFRESH_RUNNING,
    SET_PROJECT_LAST_UPDATE,
    SET_REFRESH_PROGRESS,
    SET_ALL_PROJECTS_LAST_UPDATE,
} from '../mutations';

let queueTimeout = null;

export default {
    state: {
        gathering: false,
        isRefreshRunning: false,
        lastUpdate: defaultLastUpdate(),
        lastUpdateAllProjects: null,
        refreshProgress: {
            clear: true,
            remaining: 0,
            total: 0,
        },
    },
    mutations: {
        [CLEAR_REFRESH_PROGRESS]: state => state.refreshProgress = Object.freeze({ clear: true, remaining: 0, total: 0 }),
        [SET_IS_REFRESH_RUNNING]: (state, payload) => {
            // Clear old data before firing API request
            if (payload) {
                state.gathering = false;
                state.lastUpdate = Object.freeze(defaultLastUpdate());
            }

            state.isRefreshRunning = payload;
        },
        [SET_REFRESH_PROGRESS]: (state, payload) => state.refreshProgress = Object.freeze(payload),
        [SET_PROJECT_LAST_UPDATE]: (state, payload) => {
            const lastUpdate = payload.updated_at;
            const date = lastUpdate.timestamp ? new Date(lastUpdate.timestamp * 1000) : new Date();

            state.gathering = payload.gathering;
            state.lastUpdate = Object.freeze({
                ...lastUpdate,
                date: switchDateFormat(
                    date,
                    LAST_UPDATE_DATE_FORMAT
                ),
            })
            if(lastUpdate.timestamp && lastUpdate.timestamp > state.lastUpdateAllProjects?.timestamp){
                state.lastUpdateAllProjects = {
                    relative: formatRelativeTime(lastUpdate.timestamp * 1000),
                    date: switchDateFormat(
                        new Date(lastUpdate.timestamp * 1000),
                        LAST_UPDATE_DATE_FORMAT
                    ),
                    timestamp: lastUpdate.timestamp
                }
            }
        },
        [SET_ALL_PROJECTS_LAST_UPDATE]: (state, payload) => {
            state.lastUpdateAllProjects = Object.freeze({
                relative: formatRelativeTime(payload * 1000),
                date: switchDateFormat(
                    payload ? new Date(payload * 1000) : new Date(),
                    LAST_UPDATE_DATE_FORMAT
                ),
                timestamp: payload
            })
        }
    },
    getters: {
        getIsRefreshGathering: state => state.gathering,
        getRefreshLastUpdate: state => state.lastUpdate,
        getIsRefreshRunning: state => state.isRefreshRunning,
        getRefreshProgress: state => state.refreshProgress,
        getAllProjectsLastUpdate: state => state.lastUpdateAllProjects
    },
    actions: {
        // first check for refreshes, launched on the each project or overview opening
        async firstRefreshesCheck({ getters, commit, dispatch }, { overview }) {
            if (getters.getViewkeyView && getters.getViewkeyAccessStatus === 'denied') {
                return;
            }

            const { getCurrentProject, getProjectByName } = getters;
            if (!getCurrentProject ||
                !getProjectByName(
                    {
                        name: getCurrentProject.name,
                        parent: getCurrentProject.parent
                    })) {
                return;
            }

            commit(SET_IS_REFRESH_RUNNING, true);

            try {
                let data;

                // project dashboard case - we have list of keywords
                if (!overview) {
                    let res;
                    const { name: routeName, params: routeParams } = router.history.current;
                    if (getters.getViewkeyView) {
                        if (
                            routeName === 'shareViewkeyWithHash' ||
                            routeName === 'shareOfVoiceWithHash' ||
                            routeName === 'shareKeywordsTableWithHash'
                        ) {
                            // view-key api with hash
                            res = await api.fetchQueueStatsForShareViewkeysWithHash(routeParams.hash);
                        } else {
                            res = await api.fetchQueueStatsForShareViewkeys(getCurrentProject)
                        }
                    } else {
                        res = await api.fetchQueueStats(getCurrentProject);
                    }

                    data = res.data.data;
                    commit(SET_PROJECT_LAST_UPDATE, data);
                }

                // project overview case - we have no keywords
                if (overview) {
                    const res = await api.fetchQueueStatsForUser();
                    data = res.data.data;
                }

                // if remaining/total is 0 OR it is share mode - stop refreshes
                !data || !data.remaining || !data.total || getters.getViewkeyView
                    ? commit(SET_IS_REFRESH_RUNNING, false)
                    : dispatch('refreshRemainingKeywords', { project: !overview && getCurrentProject, overview });
            } catch (error) {
                commit(SET_IS_REFRESH_RUNNING, false);
            }
        },
        // refreshes trigger (on button click)
        async refreshKeywords({ getters, commit, dispatch }, { overview }) {
            if (!getters.getPermissionData('on_demand_updates')) {
                toastr.w(i18n.t('upgrade-for-on-demand-updates'));
                return false;
            }

            const { params } = router.history.current;
            const id = decodeURIComponent(params.id);
            const project = getters.getCurrentProject;

            let includeSubGroups = false;
            if (!overview && (id && (!project || !project.name || project.id !== id))) {
                toastr.e(i18n.t('project-not-found'));
                return;
            }
            commit(SET_IS_REFRESH_RUNNING, true);
            let projectName;
            let data = {};
            let queryParams = {};
            // in case of project dashboard
            if (!overview) {
                queryParams = getFetchFilters(getters);
                projectName = params.id ? getters.getFullProjectName(project) : null;
                const keywords = getters.getSelectedKeywordsWithAllData;
                const withoutIds = getters.getAllWithoutIdsData;
                let keywordsNumber = keywords.length || project.keywords_count.ACTIVE;

                // include the subgroups in the API call
                includeSubGroups = getters.getOtherSetting('subcat') === 'show'

                if (projectName) {
                    data.project_ids = [project.project_id];
                }

                if (keywords.length) {
                    data.keyword_ids = [];
                    keywords.forEach(el => data.keyword_ids.push(el.id));
                }

                // if display subgroup options is set, keywordsNumber is the sum of project and subproject keywords
                if (keywords.length === 0 && getters.getOtherSetting('subcat') === 'show') {
                    keywordsNumber = getters.getProjectAndSubprojectsKeywordsCount(project);
                }
                if (withoutIds.length) {
                    data.excluded_keyword_ids = withoutIds;
                }
                commit(SET_REFRESH_PROGRESS, { remaining: keywordsNumber, total: keywordsNumber });
                await dispatch('fetchUsedRefreshes');
                data.include_sub_groups = includeSubGroups;
            } else {
                queryParams = getFetchFilters(getters);
                data.project_ids = getters.getCheckedProjects.map(p => getters.getProjectById(p).project_id)
            }

            try {
                await keywordsApi.refresh({ data }, queryParams);
                EventBus.emit(Events.USED_ON_DEMAND_UPDATE, data);

                if (!overview) {
                    dispatch('setKeywordsRefreshing', data);
                    // dispatch('fetchKeywords', {id: project.id, refreshing: data});
                }

                dispatch('refreshRemainingKeywords', { project, overview });
            } catch (error) {
                commit(SET_IS_REFRESH_RUNNING, false);
                error.response?.data?.errors?.forEach(error => {
                    if(error.detail.includes('<a href=\"/packages\">View Larger Packages</a>')){
                        if(getters.getIsFreePlan){
                            toastr.e(error.detail);
                        } else {
                            toastr.e(error.detail.replace(
                                '<a href=\"/packages\">View Larger Packages</a>',
                                '<a href=\"/dashboard?showResetPrompt=1\">Reset on demand refresh limit</a>'
                            ));
                        }
                    } else {
                        toastr.e(error.detail);
                    }
                });
                console.error(error);
            }
        },
        // method that launches every 5s after refreshes was triggered and not finished yet
        refreshRemainingKeywords({ getters, commit, dispatch }, { project, overview }) {
            if (!overview && (!project || !getters.getCurrentProject)) {
                return;
            }

            async function requestRemain(project, overview) {
                clearTimeout(queueTimeout);
                const routeName = router.history.current.name;

                if (!['Overview', 'keywordList'].includes(routeName)) {
                    return;
                }

                // invalid case for project dashboard - if we go away from current page
                if (project) {
                    if (project.auth !== getters.getCurrentProject?.auth && routeName === 'keywordList') {
                        return;
                    }
                    if (routeName === 'Overview') {
                        return;
                    }
                }

                // invalid case for overview dashboard - if we go away from current page
                if (!project && overview && routeName !== 'Overview') {
                    return;
                }
                commit(SET_IS_REFRESH_RUNNING, true);

                try {
                    let res;
                    let data;

                    // case for project dashboard
                    if (!overview) {
                        res = getters.getViewkeyView
                            ? await api.fetchQueueStatsForShareViewkeys(project)
                            : await api.fetchQueueStats(project);

                        data = res.data.data;
                        commit(SET_PROJECT_LAST_UPDATE, data);
                    }

                    // case for dashboard overview
                    if (overview) {
                        res = await api.fetchQueueStatsForUser();
                        data = res.data.data;
                    }

                    commit(SET_REFRESH_PROGRESS, data);

                    if (data && parseInt(data.remaining) === 0) {
                        commit(SET_IS_REFRESH_RUNNING, false);
                    } else {
                        queueTimeout = setTimeout(() => requestRemain(project, overview), 5000);
                    }
                } catch (e) {
                    console.warn(e);
                    commit(SET_IS_REFRESH_RUNNING, false);
                    dispatch('clearRefreshProgress');
                } finally {
                    await dispatch('fetchUsedRefreshes');
                }
            }

            requestRemain(project, overview);
        },
        setKeywordsRefreshing({ getters, dispatch }, data) {
            if (data.include_sub_groups) {
                let subProjects = [];

                data.project_ids.map(p_id => {
                    subProjects = subProjects.concat(
                        getters.getSubProjectsByParentId(p_id)
                            .map(c => c.project_id)
                    )
                });

                data.project_ids = data.project_ids.concat(subProjects);
            }

            let newOriginalItems = getters.getOriginalItems.map(kw => {
                if (
                    (!data.keyword_ids || data.keyword_ids.includes(kw.id)) &&
                    (!data.excluded_keyword_ids || !data.excluded_keyword_ids.includes(kw.id)) &&
                    data.project_ids.includes(kw.project_id)
                ) {
                    kw.grank = null;
                    kw.gpage = null;
                    kw.clicks = null;
                    kw.impressions = null;
                    kw.ctr = null;
                }

                return kw;
            });

            dispatch('setKeywords', newOriginalItems);
            dispatch('fetchGatheringKeywords');
        },
        clearRefreshProgress({ commit }) {
            commit(CLEAR_REFRESH_PROGRESS);
        },
    },
};
