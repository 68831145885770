<template>
    <div class="refresh">
        <kw-button
            v-if="!isViewOnlyUser && !getViewkeyView && !getGlobalSearchQuery"
            class="refresh-btn-dashboard"
            button-type="secondary"
            append-icon-class="kw kw-refresh-update"
            :class="refreshButtonClasses"
            data-cy="button-refresh"
            :data-important-tooltip="refreshTooltip"
            :data-important-max-width="170"
            :loading="getIsRefreshRunning"
            @click="handleRefreshKeywords"
            :buttonDisabled="disabled"
        />
        <div class="refresh__header">
            <div :class="titleWrapperClasses">
                <div v-if="type !== 'overview'" class="title-favicons">
                    <img
                        v-for="(domain, i) in domains"
                        :key="i"
                        :alt="domain"
                        :src="`https://www.google.com/s2/favicons?sz=64&domain_url=${domain}`"
                        @mouseover="favTooltip"
                    />

                    <div
                        v-if="getListOfKeywordsDomains.length > 3"
                        class="title-favicons__more"
                        @mouseover="favTooltip"
                        v-text="$t('more-project')"
                    />
                </div>

                <div
                    class="title-name"
                    data-cy="dashboard-project-name"
                    @mouseover="getTooltipForText(title, $event)"
                    v-text="title"
                />
                <div v-if="getActiveTag.id" class="title-active-tag-name">
                    <span class="kw kw-corner-down-right" />
                    {{ getActiveTag.name }}
                </div>
            </div>
            <refresh-label
                v-if="showRefreshLabel"
                :last-update="getLastUpdate"
                :refresh-running="getIsRefreshRunning"
                :refresh-progress="getRefreshProgress"
                :title="title"
                :isEmpty="isEmpty"
            />
            <div v-else-if="subTitle && hideReportPdfDate" class="sub_title">
                {{ subTitle }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RefreshLabel from './components/RefreshLabel';
import RefreshMixin from '@/mixins/RefreshMixin';
import {
    switchDateFormat,
    formatRelativeTime,
} from '@/helpers/format-date-service';
import { LAST_UPDATE_DATE_FORMAT } from '@/constants';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
export default {
    name: 'Refresh',
    components: {
        'refresh-label': RefreshLabel,
    },
    mixins: [RefreshMixin, ToolTipRenderMixin],
    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },
        type: {
            type: String,
            required: false,
            default: '',
        },
        showRefreshLabel: {
            type: Boolean,
            required: false,
            default: true,
        },
        subTitle: {
            type: String,
            required: false,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters([
            'getCurrentProject',
            'getCurrentPlan',
            'getIsRefreshRunning',
            'getSearchKeyword',
            'getListOfKeywordsDomains',
            'getRefreshLastUpdate',
            'getRefreshProgress',
            'getSelectedAuth',
            'isViewOnlyUser',
            'getViewkeyView',
            'getOtherSetting',
            'getGlobalSearchQuery',
            'getActiveTag',
            'getProjectByName',
            'getAllProjectsLastUpdate'
        ]),
        isEmpty(){
            if((this.$route.name === 'Overview' && this.getAllProjectsLastUpdate) || this.getRefreshProgress?.remaining) return false;

            if(!this.getCurrentProject) return true;

            const children = this.getProjectByName(this.getCurrentProject)?.project.children || null;

            if(children?.find(item=> !!item.keywords_count)){
                return false
            }

            if(this.getViewkeyView){
                return !this.getCurrentProject.updated_at;
            }

            return !this.getCurrentProject.keywords_count?.ACTIVE;
        },
        getLastUpdate() {
            if(this.$route.name === 'Overview' && this.getAllProjectsLastUpdate){
                const { timestamp, relative } = this.getAllProjectsLastUpdate;
                return {
                    ...this.getAllProjectsLastUpdate,
                    relative: timestamp ? formatRelativeTime(new Date(timestamp * 1000)) : relative
                };
            }
            if (this.getCurrentProject) {

                const children = this.getProjectByName(this.getCurrentProject)?.project.children || null;
                let timestamp = this.getCurrentProject ? this.getCurrentProject.crawled_at : null;
                if(this.getRefreshLastUpdate?.timestamp > timestamp) {
                    timestamp = this.getRefreshLastUpdate.timestamp;
                }
                if(children){
                    children.forEach(item=>{
                        if(item.crawled_at > timestamp){
                            timestamp = item.crawled_at;
                        }
                    })
                }
                const date = timestamp ? new Date(timestamp * 1000) : new Date();

                return {
                    relative: formatRelativeTime(date),
                    date: switchDateFormat(date, LAST_UPDATE_DATE_FORMAT),
                    timestamp: timestamp,
                };
            }

            return this.getRefreshLastUpdate;
        },
        domains() {
            return this.getListOfKeywordsDomains.length &&
                !this.getGlobalSearchQuery
                ? this.getListOfKeywordsDomains.slice(0, 3)
                : ['domain.com'];
        },
        refreshButtonClasses() {
            return {
                refresh__button: true,
                'button-outline_pseudo-disabled':
                    this.getCurrentPlan.expiredStatus === 'expired' || this.disabled,
            };
        },
        titleWrapperClasses() {
            return {
                'refresh__title-wrapper': true,
                'refresh__title-wrapper_multiple-favicons':
                    this.getListOfKeywordsDomains.length > 1,
            };
        },
        hideReportPdfDate() {
            return (
                this.getCurrentProject &&
                this.getProjectSetting('hideReportPdfDate')
            );
        },
    },
    methods: {
        favTooltip(event) {
            return this.tooltip(
                this.getListOfKeywordsDomains.length > 3
                    ? { text: this.$t('multiple-domains'), event }
                    : { disabled: true },
            );
        },
        getProjectSetting(setting) {
            return this.getCurrentProject.settings.columns.find(
                item => item === setting,
            );
        },
    },
};
</script>

<style lang="scss">
.refresh {
    align-items: center;
    display: flex;
    @media (min-width: 960px) {
        width: 50%;
    }

    button {
        &.kw-btn-secondary {
            width: 35px;
            padding: 8px 8px !important;
        }
        span {
            &.kw-refresh-update {
                font-size: 20px;
            }
        }
    }
}

.refresh__button {
    margin-right: 14px;
    position: relative;

    .button-loader {
        margin: 0 !important;
    }

    @media (max-width: 500px) {
        margin-right: 8px;
    }
}

.refresh__header {
    max-width: calc(100% - 50px);
    @media (max-width: 959px) {
        max-width: 255px;
    }
}

.refresh__title-wrapper {
    display: flex;
    align-items: center;
}

.title-favicons {
    cursor: default;
    display: flex;
    height: 16px;
    margin-left: 0;
    margin-right: 5px;

    img {
        border-radius: 50%;
        height: auto;
        width: 16px;
    }
}

.refresh__title-wrapper_multiple-favicons {
    .title-name {
        margin-right: 5px;
        order: 1;
    }

    .title-favicons {
        margin-right: 0;
        order: 2;
    }

    .title-active-tag-name {
        order: 3;
    }
}

.title-favicons__more {
    margin-left: 5px;
    white-space: nowrap;
}

.title-name,
.title-active-tag-name {
    color: kw-color(kw-black, 1);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.title-active-tag-name {
    padding-left: 5px;
    display: flex;
    align-items: center;

    i {
        margin-right: 8px;
    }
}

.sub_title {
    color: #909399;
}

@media screen and (max-width: 959px) {
    .title-favicons {
        margin-right: 10px;
    }
    .refresh__title-wrapper_multiple-favicons {
        .title-favicons {
            margin-left: 10px;
            margin-right: 0;
        }
    }
}
</style>
